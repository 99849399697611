import axios from 'axios';
import { getAccessToken } from './auth.js';
import { SURVEYS_MS_URL } from '../../constants/api';
import logger from '@frello-tech/front-utils/dist/utils/logger.js';

export const updateSurveyTime = async ({ authData, time, surveyId }) => {
  const url = SURVEYS_MS_URL;

  if (!surveyId) {
    return;
  }

  const data = JSON.stringify({
    query: `mutation IncrementSurveySpentTime($id: ID!, $time: Int!) {
        incrementSurveySpentTime(id: $id, time: $time)
      }`,
    variables: {
      id: surveyId.toString(),
      time
    }
  });

  const axiosConfig = {
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${await getAccessToken(authData)}`,
      'Content-Type': 'application/json'
    },
    data
  };

  try {
    axios(axiosConfig);
  } catch (error) {
    logger.error(error);
  }
};

export const updateSurveyFeedback = async ({ authData, id, comment, liked }) => {
  const url = SURVEYS_MS_URL;

  if (!id) {
    return;
  }

  const data = JSON.stringify({
    query: `mutation updateSurveyFeedback($id: ID!, $comment: String, $liked: Boolean) {
        updateSurveyFeedback(id: $id, comment: $comment, liked: $liked)
    }`,
    variables: {
      id: id.toString(),
      comment,
      liked
    }
  });

  const axiosConfig = {
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${await getAccessToken(authData)}`,
      'Content-Type': 'application/json'
    },
    data
  };

  try {
    axios(axiosConfig);
  } catch (error) {
    logger.error(error);
  }
};

export default {
  LARGE_HORIZONTAL_PADDING: 21,
  LARGE_VERTICAL_PADDING: 11,
  SMALL_HORIZONTAL_PADDING: 10,
  SMALL_VERTICAL_PADDING: 5,
  EXTRA_SMALL_HORIZONTAL_PADDING: 5,
  FONT_SIZE_MEDIUM: 18,
  FONT_SIZE_LARGE: 22,
  FONT_FAMILY_REGULAR: 'raleway',
  FONT_FAMILY_MEDIUM: 'raleway',
  FONT_FAMILY_SEMI_BOLD: 'raleway',
  FONT_FAMILY_BOLD: 'raleway',
  FONT_SIZE_EXTRA_SMALL: 14,
  FONT_SIZE_SMALL: 16,
  FONT_SIZE_STANDARD: 20,
  FONT_SIZE_EXTRA_LARGE: 24,
  LINE_HEIGHT: 25,
  BORDER_STYLE: 'solid',
  BORDER_STYLE_DASH: 'dashed',
  SMALL_BORDER_WIDTH: 1,
  SMALL_BORDER_RADIUS: 4,
  MEDIUM_BORDER_WIDTH: 2,
  EXTRA_WIDE_BORDER_RADIUS: 16,
  WIDE_BORDER_RADIUS: 10,
  MEDIUM_BORDER_RADIUS: 6,
  BLUE: '#0033FF',
  BLUE_LIGHT_1: '#1a47ff',
  DARK_BLUE: '#0029CF',
  NAVY_BLUE: '#000066',
  BLUE_DARK_1: '#033EF5',
  BLUE_LIGHT_2: '#C6EDFD',
  BLUE_LIGHT_3: '#59B0F8',
  BLUE_LIGHT_4: '#C6D3EA',
  WHITE: '#ffffff',
  BLACK: '#000000',
  GREY: '#d3d3d3',
  LIGHT_GREY: '#f0f0f0',
  BACKGROUND_GREY: '#F2F5FA',
  TEXT_GREY: '#575756',
  TEXT_GREY_LIGHTER: '#6f6f6e',
  BLACK_BLUE: '#373C45',
  OPACITY_BLUE: 'rgba(0, 51, 255, 0.08)',
  OPACITY_BLUE_05: 'rgba(0, 51, 255, 0.5)',
  OPACITY_BLACK_BLUE: 'rgba(55, 60, 69, 0.08)',
  PROGRESS_BAR_GREY: '#DEDFE3',
  OPAC_GREY: 'rgba(211, 211, 211, 0.5)',
  CORRECT_GREEN: '#E5FFF7',
  CORRECT_DARK_GREEN: '#00BC83',
  WRONG_RED: '#FFE7E7',
  WRONG_DARK_RED: '#FF3333',
  transparent: 'transparent',
  primary: {
    900: '#001466',
    800: '#001F99',
    700: '#0029CC',
    600: '#0033FF',
    500: '#335CFF',
    400: '#6685FF',
    300: '#99ADFF',
    200: '#ADBEFF',
    100: '#CCD6FF',
    50: '#E0E7FF'
  },
  grey: {
    50: '#E3E4E6',
    100: '#D4D7DD',
    200: '#A9AFBA',
    300: '#7E8798',
    400: '#c1c4c8',
    500: '#373C45',
    600: '#919396',
    700: '#797a7d',
    800: '#606264'
  },
  blue: {
    background: '#E0E7FF',
    border: '#335CFF',
    font: '#036282',
    50: '#E5EBFF',
    400: '#6685FF',
    500: '#335CFF'
  },
  orange: {
    main: '#FFCA04',
    500: '#FFCA04',
    background: '#F27900'
  },
  red: {
    background: '#FFE7E7',
    border: '#FF3333',
    font: '#AD2323',
    main: '#ff6666',
    50: '#FFD6D6'
  },
  green: {
    40: '#0BC18A',
    500: '#00BC83',
    background: '#E5FFF7',
    border: '#00BC83',
    font: '#375E00'
  }
};

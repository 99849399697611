import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../js/theme';
import ContainerUI from '@material-ui/core/Container';
import { Provider, connect } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
import configureStore from '../js/configureStore';
import 'react-image-lightbox/style.css';
import '../static/css/imageModal.css';
import { AUTO_CONNECT_IFRAME, SET_LOADED, SET_LOADING, SET_SHOW_BEFORE_LEAVE_WARNING_FALSE } from '../js/actions';
import interceptor from '@frello-tech/front-utils/dist/Interceptors/AxiosLoadingInterceptor';
import axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import logger from '@frello-tech/front-utils/dist/utils/logger';
import soundEffect from '../js/services/soundEffect';

import ConnectionQuality from '../components/organisms/ConnectionQuality';
class FrelloModule extends App {
  constructor (props) {
    super(props);
    logger.setSentry(Sentry);
  }

  static async getInitialProps ({ Component, ctx }) {
    const pageProps = {};
    if (Component.getInitialProps) {
      const {
        query: {
          tab: tabId,
          access_token: accessToken,
          section: sectionId,
          evaluation_session: evaluationSessionId,
          module: moduleId,
          resume,
          classroom: classroomId,
          extension,
          index: activityIndex,
          version: moduleVersionId,
          transition,
          survey: surveyId,
          locale,
          redirect_uri: redirectURI,
          impersonates,
          flash_positioning: flashPositioning,
          isDoingSessionAutonomously
        }, req
      } = ctx;
      const userAgent = req && req.headers['user-agent'];
      ctx.store.dispatch({
        type: AUTO_CONNECT_IFRAME,
        payload: {
          accessToken,
          tabId,
          sectionId: sectionId !== 'undefined' ? sectionId : undefined,
          evaluationSessionId,
          moduleId,
          classroomId: classroomId !== 'undefined' ? classroomId : undefined,
          resume,
          userAgent,
          extension: extension !== 'undefined' ? extension : undefined,
          activityIndex,
          moduleVersionId: moduleVersionId !== 'undefined' ? moduleVersionId : undefined,
          transition,
          redirectURI,
          surveyId,
          locale,
          impersonates,
          flashPositioning,
          isDoingSessionAutonomously: isDoingSessionAutonomously
        }
      });
    }
    return { pageProps };
  }

  componentDidMount () {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
    const {
      autoConnect,
      setLoading,
      setLoaded,
      setShowBeforeLeaveWarningFalse
    } = this.props;
    autoConnect();
    interceptor.onLoad(axios, setLoading);
    interceptor.onLoaded(axios, setLoaded);

    /**
     * TODO: add request interceptor to try to renew the access token if expired or close to expiry
     *
     * Response interceptor will be used in last resort
     */
    axios.interceptors.response.use((response) => response, (error) => {
      const {
        isMaskoot: isMaskott,
        lti: isLti,
        cmi5: isCMI5
      } = this.props.user;

      // cannot renew token for interop for now
      if (isMaskott || isLti || isCMI5) {
        return Promise.reject(error);
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if ([400, 401].includes(error?.response?.status)) {
        const alreadyReloaded = sessionStorage.getItem('mvwa_reload') === 'true';
        // avoid reload infinite loop
        if (alreadyReloaded) {
          sessionStorage.setItem('mvwa_reload', false);
        } else {
          sessionStorage.setItem('mvwa_reload', true);
          setShowBeforeLeaveWarningFalse();
          // the backend will perform the re-authentication process
          // the user will only loose his last activity / action
          window.location.reload();
        }
      }

      return Promise.reject(error);
    });

    // clean the url as all the state as been stored
    window.history.replaceState({}, null, window.location.href.split('?')[0]);

    logger.debug('[app] loading result sounds');
    soundEffect.loadSounds();
  }

  render () {
    const {
      showBeforeLeaveWarning,
      Component,
      pageProps,
      store,
      tabId
    } = this.props;

    if (typeof window !== 'undefined') {
      window.onbeforeunload = function (e) {
        let path = window.location.pathname;
        try {
          /**
           * Define the cookie on the module path so that the one set on activity N is available for activity M
           * (cookie on /path is available on /path/subpath but not the one on /path/subpath1 for /path/subpath2)
           */
          const pathElements = path.split('/');
          const moduleIdElIndex = pathElements.indexOf('module') + 1;
          path = pathElements.slice(0, moduleIdElIndex + 1).join('/');
        } catch (error) {
          // in case an error occurs the cookie is still set on the base path
        }
        /**
         * Replace tab id cookie just before unload
         * This allows the backend to get the config from cookies
         */
        if (tabId) {
          document.cookie = `FRELLO_TAB_ID=; Path=${path}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          document.cookie = `FRELLO_TAB_ID=${tabId}; path=${path}`;
        }

        e = e || window.event;

        if (showBeforeLeaveWarning) {
          // For IE and Firefox prior to version 4
          if (e) {
            e.returnValue = 'Sure?';
          }

          // For Safari
          return 'Sure?';
        }
      };
    }

    return (
      <Provider store={store}>
        <Head>
          <title>Frello</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
          <link href="/static/css/button.css" rel="stylesheet" />
          <link rel="shortcut icon" href="/static/img/favicon.ico" />
          <meta charSet="utf-8" />
          {/* Use minimum-scale=1 to enable GPU rasterization */}
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
          {/* PWA primary color */}
          <meta name="theme-color" content={theme.palette.primary.main} />
          <link href="https://fonts.googleapis.com/css2?family=Dancing+Script&family=Merriweather:ital,wght@0,300;0,400;1,300;1,400&family=Raleway:ital,wght@0,300;0,400;0,700;0,800;1,400;1,700;1,800&display=swap" rel="stylesheet"/>
          <style>
            {`
            @font-face {
                font-family: "EcritureA Orne";
                src: url("/static/fonts/EcritureA-Romain-Orne.otf") format("opentype");
                font-style: normal;
                font-weight: normal;
            }
            [style*="EcritureA Orne"] {
              font-size: 40px;
              line-height: 40px;
            }
            `}
          </style>
        </Head>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <ContainerUI
            maxWidth={false}
            style={{ padding: 0, margin: 0 }}
          >
            <Component {...pageProps} />
            <ConnectionQuality />
          </ContainerUI>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

const mapStateToProps = state => ({
  showBeforeLeaveWarning: state.ui.showBeforeLeaveWarning,
  tabId: state.ui.tabId,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  setLoading: resource => {
    if (
      resource.includes('http://localhost:8012/graphql') ||
      resource.includes('apis.frello.eu/surveys')
    ) {

    } else {
      return dispatch({ type: SET_LOADING, payload: { resource } });
    }
  },
  setLoaded: resource => dispatch({ type: SET_LOADED, payload: { resource } }),
  autoConnect: () => dispatch({ type: AUTO_CONNECT_IFRAME, payload: {} }),
  setShowBeforeLeaveWarningFalse: () => dispatch({ type: SET_SHOW_BEFORE_LEAVE_WARNING_FALSE })
});

export default withRedux(configureStore)(withReduxSaga(connect(mapStateToProps, mapDispatchToProps)(FrelloModule)));

import { call, select, put, takeLeading } from 'redux-saga/effects';
import logger from '@frello-tech/front-utils/dist/utils/logger';
import {
  CUSTOM_LEAVE_MODULE,
  GO_TO_CUSTOM_END,
  SET_SHOW_BEFORE_LEAVE_WARNING_FALSE,
  SET_SHOW_BEFORE_LEAVE_WARNING_TRUE,
  GO_TO_END_MODULE
} from '../actions';
import { retrieveLaunchData } from './CMI5';
import {
  getCurrentModuleId,
  getLtiInfo
} from '../selectors';
import { translate } from '../services/i18n';
import { navigate } from './navigate';
import {
  getCurrentSectionId
} from '@frello-tech/front-utils/dist/store/selectors';
import { MAIN_URL_FRELLO } from '../constants/api';

function * flow () {
  const isCMI5inter = yield call(retrieveLaunchData);
  const user = yield select(getLtiInfo);

  if (!isCMI5inter && !user.isLti && !user.isMaskoot) {
    yield takeLeading(GO_TO_CUSTOM_END, goToCustomEnd);
    yield takeLeading(CUSTOM_LEAVE_MODULE, leaveModule);
  }
}

function * goToCustomEnd () {
  const moduleCompletionMode = yield select(state => state.ui.moduleCompletionMode);

  switch (true) {
  case moduleCompletionMode === 'previsualisation':
    yield put({ type: GO_TO_END_MODULE });
    break;

  default:
    yield call(goToTransition);
    break;
  }
}

function * goToTransition () {
  try {
    const sectionId = yield select(getCurrentSectionId);
    const moduleId = yield select(getCurrentModuleId);

    if (sectionId > -1) {
      return yield call(navigate, {
        payload: {
          as: `/section/${sectionId}/transition`,
          href: '/transition'
        }
      });
    }
    yield call(navigate, {
      payload: {
        as: `/module/${moduleId}/transition`,
        href: '/transition'
      }
    });
  } catch (e) {
    yield call(console.log, '[Saga - Frello - goToTransition]');
    logger.error(e);
  }
}

function * leaveModule ({ payload }) {
  try {
    const redirectURI = yield select(state => state.ui.redirectURI);
    const flashPositioning = yield select(state => state.ui.flashPositioning);

    if (flashPositioning && payload?.result !== undefined && redirectURI) {
      return yield call(navigate, {
        payload: {
          href: `${redirectURI}?positioning_result=${payload.result}`
        }
      });
    } else if (redirectURI) {
      const moduleCompletionMode = yield select(state => state.ui.moduleCompletionMode);
      const redirect = () => {
        document.location.href = redirectURI;
      };
      if (moduleCompletionMode === 'evaluation' && !(payload && payload.noConfirm)) {
        yield put({ type: SET_SHOW_BEFORE_LEAVE_WARNING_FALSE });

        // warn the user that he won't be able to retry
        window.confirm(
          translate('evaluation.beforeLeaveWarning')
        ) && redirect();

        yield put({ type: SET_SHOW_BEFORE_LEAVE_WARNING_TRUE });
      } else {
        // redirect anyway as user is able to retry
        redirect();
      }
    } else {
      return yield call(navigate, {
        payload: {
          href: MAIN_URL_FRELLO
        }
      });
    }
  } catch (error) {
    logger.error(error);
  }
}

export default flow;

import axios from 'axios';
import { getAccessToken } from './auth.js';
import { CONTENTS_MS_URL, SCHOOLS_MS_URL } from '../../constants/api';
import logger from '@frello-tech/front-utils/dist/utils/logger.js';

export const getSchoolSettings = async ({ authData, classroomId }) => {
  const url = SCHOOLS_MS_URL;
  const data = JSON.stringify({
    query: `query school($classroomId: ID!) {
      school(classroomId: $classroomId) {
      settings {
        correctionAiAccess
      }
    }
  }`,
    variables: {
      classroomId: classroomId
    }
  });

  const axiosConfig = {
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${await getAccessToken(authData)}`,
      'Content-Type': 'application/json'
    },
    data
  };

  try {
    const response = await axios(axiosConfig);
    return response.data?.data?.school.settings;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const getSchoolLogo = async ({ authData, id }) => {
  const url = CONTENTS_MS_URL;
  const data = JSON.stringify({
    query: `query module($moduleId: ID) {
      module(id: $moduleId) {
        schoolLogo
      }
    }`,
    variables: {
      moduleId: id
    }
  });

  const axiosConfig = {
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${await getAccessToken(authData)}`,
      'Content-Type': 'application/json'
    },
    data
  };

  try {
    const response = await axios(axiosConfig);
    return response.data?.data?.module.schoolLogo;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

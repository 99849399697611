import isNil from 'lodash/isNil';
import get from 'lodash/get';
import {
  FETCH_MODULES,
  FETCH_MODULES_SUCCESS,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_SURVEY,
  FETCH_SURVEY_SUCCESS,
  END_MODULE,
  END_MODULE_SUCCESS,
  SEND_RESPONSE,
  SEND_RESPONSE_SUCCESS,
  GO_TO_PREVIOUS_ACTIVITY,
  GO_TO_NEXT_ACTIVITY,
  GO_TO_FIRST_ACTIVITY,
  FETCH_MODULE_WITH_ACTIVITIES,
  RESET_SURVEY_AND_GO_TO_FIRST_ACTIVITY,
  SEE_CORRECTION_MODULE,
  SET_SURVEY_IS_LOADED_TRUE,
  ADD_ENTITIES,
  LEAVE_MODULE,
  GO_TO_END_MODULE,
  UNLOAD_SOUND,
  SEND_RESPONSE_WITH_FILE,
  GO_TO_CUSTOM_END,
  CUSTOM_LEAVE_MODULE,
  SEND_RESPONSE_WITH_FILE_SUCCESS,
  SEND_RESPONSE_WITH_FILE_FAIL,
  GO_TO_FIRST_ACTIVITY_NOT_DONE,
  FETCH_TRANSITION_SUCCESS,
  FETCH_TRANSITION,
  RESET_ACTIVITY_TIME,
  SEE_CORRECTION_MODULE_SUCCESS,
  SEND_RESPONSE_FOR_AUTO_CORRECTION
} from '../actions';
import { call, put, takeLatest, select, delay, takeLeading, take } from 'redux-saga/effects';
import {
  fetchModules,
  fetchModuleByIdWithActivities,
  fetchSurvey,
  postResponsesForQuestion,
  endModuleById,
  resetSurvey,
  postSoundResponse,
  pauseModuleById,
  patchResponse
} from '../services/api/module';
import {
  getCurrentNBactivities,
  getCurrentModuleId,
  getSurvey,
  getCurrentActivityPoint,
  getSound,
  getActivities,
  addOrderToResults,
  addOrderToSurveyResponses,
  getAuthData,
  getCurrentEvaluationSessionId,
  getModuleResolutionData,
  getCurrentSurveyId,
  getCurrentSurveyStatus,
  selectIsDoingSessionAutonomously,
  getModuleById
} from '../selectors';
import {
  getCurrentActivityResponse,
  isAutoCorrected,
  getCurrentSectionId,
  getAdditionalMedia
} from '@frello-tech/front-utils/dist/store/selectors';
import { callApi } from './apiRetry';
import { navigate } from './navigate';
import { getCurentActivity } from './CMI5';
import { normalize } from 'normalizr';
import { moduleSchema, surveySchema } from '../models';
import soundEffect from '../services/soundEffect';
import logger from '@frello-tech/front-utils/dist/utils/logger';
import { fetchTransition } from '../services/api/transition';
import { updateSurveyTime } from '../services/api/survey';
import { sendResponseForAutoCorrection } from '../services/api/autoCorrection';

function * flow () {
  yield takeLatest(FETCH_MODULES, loadModules);
  yield takeLatest(FETCH_MODULE_WITH_ACTIVITIES, loadModuleByIdWithActivities);
  yield takeLatest(FETCH_SURVEY, loadSurvey);
  yield takeLeading(SEND_RESPONSE, sendResponses);
  yield takeLeading(GO_TO_PREVIOUS_ACTIVITY, prevActivity);
  yield takeLeading(GO_TO_NEXT_ACTIVITY, nextActivity);
  yield takeLeading(GO_TO_FIRST_ACTIVITY, firstActivity);
  yield takeLeading(RESET_SURVEY_AND_GO_TO_FIRST_ACTIVITY, resetAndGoToFirstActivity);
  yield takeLeading(SEE_CORRECTION_MODULE, seeCorrection);
  yield takeLeading(SEND_RESPONSE_SUCCESS, handleResponseSuccess);
  yield takeLeading(END_MODULE, endModule);
  yield takeLeading(GO_TO_END_MODULE, goToModuleEnd);
  yield takeLeading(SEND_RESPONSE_WITH_FILE, sendResponsesWithFile);
  yield takeLeading(LEAVE_MODULE, preLeave);
  yield takeLeading(GO_TO_FIRST_ACTIVITY_NOT_DONE, firstActivityNotDone);
  yield takeLatest(FETCH_TRANSITION, loadTransition);
}

/**
 todo : pour le moment on utilisa pas normalizr , c'est un peu mon reve pour simplifier le model
 et la gestion des sagas et reduces.
 **/

function * loadModules () {
  try {
    const authData = yield select(getAuthData);
    const moduleResolutionData = yield select(getModuleResolutionData);
    const modulesData = yield call(fetchModules, authData, moduleResolutionData);
    yield put({ type: FETCH_MODULES_SUCCESS, payload: modulesData });
    const normalizedData = normalize(modulesData, moduleSchema);
    yield put({ type: ADD_ENTITIES, entities: normalizedData });
  } catch (e) {
    logger.error(e);
  }
}

export function * loadModuleByIdWithActivities ({ payload }) {
  try {
    const authData = yield select(getAuthData);
    const moduleResolutionData = yield select(getModuleResolutionData);
    const moduleData = yield call(fetchModuleByIdWithActivities, authData, payload, moduleResolutionData);
    const normalizedData = normalize(moduleData, moduleSchema);
    yield put({ type: ADD_ENTITIES, entities: normalizedData });
    yield put({ type: FETCH_MODULES_SUCCESS, payload: { modules: [moduleData] } });
    yield put({ type: FETCH_ACTIVITIES_SUCCESS, payload: { activities: moduleData.blocs } });
  } catch (e) {
    logger.error(e);
  }
}

export function * loadSurvey ({ payload: { moduleId } }) {
  try {
    const currentActivityIndex = yield select(state => state.ui.currentActivityIndex);
    const authData = yield select(getAuthData);
    const moduleResolutionData = yield select(getModuleResolutionData);
    const { survey } = yield call(fetchSurvey, authData, moduleId, moduleResolutionData);

    if (survey === undefined) {
      return;
    }

    yield put({ type: FETCH_SURVEY_SUCCESS, payload: { survey } });
    const impersonated = yield select(state => state.ui.impersonated);
    if (!impersonated && currentActivityIndex) {
      yield call(setResponseSeenIfExists, { payload: { activityIndex: currentActivityIndex } });
    }
    const normalizedData = normalize(survey, surveySchema);
    yield put({ type: ADD_ENTITIES, entities: normalizedData });
    yield put({ type: SET_SURVEY_IS_LOADED_TRUE });
  } catch (e) {
    logger.error(e);
  }
}

/**
 * ! empty object is send even for static activities
 * ! investigate to now if this is on purpose to send a statement
 * ! if yes this should be splitted in a more explicit function name
 * ! like sendViewed if the purpose is to generate such a statement
 */
function * sendResponses ({ payload: { questionId, responses, currentActivity, goNext, skipped = false } }) {
  try {
    const moduleId = yield select(getCurrentModuleId);
    const isDoingSessionAutonomously = yield select(selectIsDoingSessionAutonomously);
    const moduleCompletionMode = yield select(state => state.ui.moduleCompletionMode);
    const activities = yield select(getActivities);
    const activity = activities[currentActivity];
    if (responses.constructor === Object) {
      const authData = yield select(getAuthData);
      const moduleResolutionData = yield select(getModuleResolutionData);
      const result = yield call(callApi, postResponsesForQuestion, authData, {
        moduleId,
        questionId,
        responses: { responses },
        skipped
      }, moduleResolutionData);
      // If doing evaluation alone, and there is no score,
      // Send the response to the auto-correction service
      if (isDoingSessionAutonomously && !result[0].reponse.score) {
        const responseTxt = result[0].reponse_txt;
        const learnerResponse = result[0].learner_response;
        yield call(autoCorrectWithAi, {
          responseTxt,
          learnerResponse,
          currentActivity,
          moduleId,
          responseId: result[0].id
        });
      }
      if (result) {
        yield call(addOrderToResults, activity, result);

        const score = get(result, '0.reponse.score', null);
        const point = yield select(getCurrentActivityPoint, currentActivity);
        const isAutoCorrectedAcitvity = yield select(isAutoCorrected, currentActivity);
        if (isAutoCorrectedAcitvity && moduleCompletionMode !== 'evaluation') {
          yield call(playSoundEndActivity, point, score);
        }
        yield put({
          type: SEND_RESPONSE_SUCCESS,
          payload: {
            questionId,
            result,
            point,
            score,
            currentActivity,
            moduleCompletionMode,
            goNext
          }
        });
      }
    }
  } catch (e) {
    logger.error(e);
  } finally {
    const activityTime = yield select(state => state.ui.activityTime);
    const survey = yield select(state => state.models.survey);
    const authData = yield select(getAuthData);
    updateSurveyTime({ time: activityTime, surveyId: survey.id, authData });
    yield put({ type: RESET_ACTIVITY_TIME });
  }
}

function * sendResponsesWithFile ({
  payload: {
    questionId,
    responses,
    currentActivity,
    goNext
  }
}) {
  try {
    const moduleId = yield select(getCurrentModuleId);
    const { sound } = yield select(getSound, 'recording');
    const moduleCompletionMode = yield select(state => state.ui.moduleCompletionMode);
    const isDoingSessionAutonomously = yield select(selectIsDoingSessionAutonomously);

    if (sound) {
      const uri = sound.src;
      if (uri) {
        const authData = yield select(getAuthData);
        const moduleResolutionData = yield select(getModuleResolutionData);
        const result = yield call(callApi, postSoundResponse, authData, {
          moduleId,
          questionId,
          responses: { responses },
          uri
        }, moduleResolutionData);

        if (result) {
          if (isDoingSessionAutonomously && !result[0].reponse.score) {
            const responseTxt = result[0].reponse_txt;
            const learnerResponse = result[0].learner_response;
            yield call(autoCorrectWithAi, {
              responseTxt,
              learnerResponse,
              currentActivity,
              moduleId,
              responseId: result[0].id
            });
          }
          yield put({
            type: SEND_RESPONSE_SUCCESS,
            payload: {
              questionId,
              currentActivity,
              result,
              point: 5,
              moduleCompletionMode,
              goNext
            }
          });
          // put send response with file success to remove SEND_RESPONSE_WITH_FILE in loading array
          yield put({ type: SEND_RESPONSE_WITH_FILE_SUCCESS });
        }
      }
    }
  } catch (e) {
    yield put({ type: SEND_RESPONSE_WITH_FILE_FAIL });
    logger.error(e);
  } finally {
    yield put({ type: UNLOAD_SOUND, payload: { src: 'recording' } });
  }
}

function * handleResponseSuccess ({
  payload: {
    goNext,
    currentActivity,
    moduleCompletionMode
  }
}) {
  try {
    const activityIndex = yield call(getCurentActivity);
    const nbActivities = yield select(getCurrentNBactivities);
    if (activityIndex >= nbActivities) {
      yield put({ type: END_MODULE });
      yield take(END_MODULE_SUCCESS);
    }

    if (moduleCompletionMode === 'evaluation' || goNext) {
      yield put({
        type: GO_TO_NEXT_ACTIVITY,
        payload: {
          currentActivity
        }
      });
    }
  } catch (e) {
    logger.error(e);
  }
}

function * endModule () {
  const survey = yield select(getSurvey);
  if (survey.termine === 0) {
    const moduleId = yield select(getCurrentModuleId);
    const authData = yield select(getAuthData);
    const moduleResolutionData = yield select(getModuleResolutionData);
    const { survey } = yield call(endModuleById, authData, moduleId, moduleResolutionData);
    const activities = yield select(getActivities);
    yield call(addOrderToSurveyResponses, activities, survey);
    yield put({ type: END_MODULE_SUCCESS, payload: { survey, isFinish: false } });
  } else {
    yield put({ type: END_MODULE_SUCCESS, payload: { survey, isFinish: true } });
  }
}

function * playSoundEndActivity (point, score) {
  if (isNil(point) || isNil(score) || point === 0) {
    return false;
  } else {
    if (parseInt(point, 10) === parseInt(score, 10)) {
      yield call(soundEffect.playVictory);
    } else {
      yield call(soundEffect.playLoose);
    }
  }
}

function * prevActivity ({ payload: { currentActivity } }) {
  try {
    const activityIndex = currentActivity - 1;
    const impersonated = yield select(state => state.ui.impersonated);
    if (!impersonated) {
      yield call(setResponseSeenIfExists, { payload: { activityIndex } });
    }
    if (activityIndex < 0) {
      yield call(navigate, { payload: { as: '/', href: '/?moduleId=101' } });
    } else {
      const moduleId = yield select(getCurrentModuleId);
      yield call(navigate, {
        payload: {
          as: `/module/${moduleId}/activity/${activityIndex + 1}`,
          href: `/activity?index=${activityIndex}`
        }
      });
    }
  } catch (e) {
    logger.error(e);
  }
}

function * nextActivity ({ payload: { currentActivity } }) {
  try {
    const moduleId = yield select(getCurrentModuleId);
    const activityIndex = currentActivity + 1;
    const nbActivities = yield select(getCurrentNBactivities);
    if (activityIndex >= nbActivities) {
      yield put({ type: GO_TO_CUSTOM_END });
    } else {
      yield call(navigate, {
        payload: {
          as: `/module/${moduleId}/activity/${activityIndex + 1}`,
          href: `/activity?index=${activityIndex}`
        }
      });
    }
    const impersonated = yield select(state => state.ui.impersonated);
    if (!impersonated && activityIndex) {
      // We set Response Seen when we go on not when we leave it
      yield call(setResponseSeenIfExists, { payload: { activityIndex: activityIndex } });
    }
    yield delay(200);
  } catch (e) {
    logger.error(e);
  }
}

function * seeCorrection () {
  const activityIndex = 0;
  const moduleId = yield select(getCurrentModuleId);
  yield call(navigate, {
    payload: {
      as: `/module/${moduleId}/activity/${activityIndex + 1}`,
      href: `/activity?index=${activityIndex}`
    }
  });
  yield put({ type: SEE_CORRECTION_MODULE_SUCCESS });
}

function * firstActivity () {
  try {
    const survey = yield select(getSurvey);
    const activityIndex = yield call(getCurentActivity);
    if (survey.termine === 1) {
      yield put({ type: GO_TO_CUSTOM_END });
    } else {
      const nbActivities = yield select(getCurrentNBactivities);
      if (activityIndex >= nbActivities) {
        yield put({ type: END_MODULE });
      } else {
        const moduleId = yield select(getCurrentModuleId);
        yield call(navigate, {
          payload: {
            as: `/module/${moduleId}/activity/${activityIndex + 1}`,
            href: `/activity?index=${activityIndex}`
          }
        });
      }
    }
  } catch (e) {
    logger.error(e);
  }
}

function * preLeave () {
  try {
    const survey = yield select(getSurvey);
    if (!survey.termine) {
      const moduleId = yield select(getCurrentModuleId);
      const moduleResolutionData = yield select(getModuleResolutionData);
      const authData = yield select(getAuthData);
      yield call(pauseModuleById, authData, moduleId, moduleResolutionData);
    }
    yield put({ type: CUSTOM_LEAVE_MODULE });
  } catch (error) {
    logger.error(error);
  }
}

function * goToModuleEnd () {
  try {
    const sectionId = yield select(getCurrentSectionId);
    if (sectionId > -1) {
      yield put({ type: FETCH_TRANSITION });
      return yield call(navigate, {
        payload: {
          as: `/section/${sectionId}/transition`,
          href: '/transition'
        }
      });
    }
    const moduleId = yield select(getCurrentModuleId);
    yield call(navigate, {
      payload: {
        as: `/module/${moduleId}/end`,
        href: '/end'
      }
    });
  } catch (e) {
    yield call(console.log, 'error end module');
    logger.error(e);
  }
}

function * resetAndGoToFirstActivity () {
  try {
    const moduleId = yield select(getCurrentModuleId);
    const surveyLoaded = yield select(getSurvey);
    const authData = yield select(getAuthData);
    const moduleResolutionData = yield select(getModuleResolutionData);
    const { survey } = yield call(resetSurvey, authData, moduleId, moduleResolutionData);
    yield put({ type: FETCH_SURVEY_SUCCESS, payload: { survey } });
    if (survey.module_version_id !== surveyLoaded.module_version_id) {
      yield put({ type: FETCH_MODULE_WITH_ACTIVITIES, payload: { moduleId } });
      // Need to wait for FETCH_SURVEY_SUCCESS before navigate
      yield take([FETCH_ACTIVITIES_SUCCESS, FETCH_SURVEY_SUCCESS]);
    }
    const activityIndex = 0;
    yield call(navigate, {
      payload: {
        as: `/module/${moduleId}/activity/${activityIndex + 1}`,
        href: `/activity?index=${activityIndex}`
      }
    });
  } catch (e) {
    yield call(console.log, 'resetAndGoToFirstActivity');
    logger.error(e);
  }
}

function * setResponseSeenIfExists ({ payload: { activityIndex } }) {
  try {
    const nbActivities = yield select(getCurrentNBactivities);

    if (activityIndex >= nbActivities) {
      return;
    }

    const response = yield select(state => getCurrentActivityResponse(state, activityIndex));
    if (response) {
      const authData = yield select(getAuthData);
      const moduleResolutionData = yield select(getModuleResolutionData);
      yield call(patchResponse, authData, response.id, {
        seenAt: new Date()
      }, moduleResolutionData);
    }
  } catch (e) {
    yield call(console.log, 'Error setResponseSeenIfExists');
    logger.error(e);
  }
}

function * firstActivityNotDone () {
  try {
    const activityIndex = yield call(getCurentActivity);
    const nbActivities = yield select(getCurrentNBactivities);
    if (activityIndex >= nbActivities) {
      yield put({ type: END_MODULE });
    } else {
      const moduleId = yield select(getCurrentModuleId);
      yield call(navigate, {
        payload: {
          as: `/module/${moduleId}/activity/${activityIndex + 1}`,
          href: `/activity?index=${activityIndex}`
        }
      });
    }
  } catch (e) {
    logger.error(e);
  }
}

function * loadTransition () {
  try {
    const moduleCompletionMode = yield select(state => state.ui.moduleCompletionMode);
    const currentSurveyStatus = yield select(getCurrentSurveyStatus);
    const isModuleDoneFreely = moduleCompletionMode === 'libre';
    const isHomework = moduleCompletionMode === 'devoir';
    const moduleId = yield select(getCurrentModuleId);
    const sectionId = yield select(getCurrentSectionId);
    const evaluationSessionId = yield select(getCurrentEvaluationSessionId);
    const surveyId = yield select(getCurrentSurveyId);
    const authData = yield select(getAuthData);
    const transition = yield call(fetchTransition, authData, { sectionId, moduleId, evaluationSessionId, surveyId, isModuleDoneFreely });
    yield put({ type: FETCH_TRANSITION_SUCCESS, payload: { transition } });
    if ((isModuleDoneFreely || isHomework) && currentSurveyStatus !== 'waiting_for_correction') {
      yield call(soundEffect.playEndModule);
    }
  } catch (e) {
    logger.error(e);
  }
}

function * autoCorrectWithAi (payload) {
  try {
    const {
      responseTxt, learnerResponse, currentActivity, moduleId, responseId
    } = payload;
    const authData = yield select(getAuthData);
    const point = yield select((state) => getCurrentActivityPoint(state, currentActivity));
    const module = yield select((state) => getModuleById(state, moduleId));
    const level = module?.nom.match(/\b[A-D][1-2](?=U?\d*L?\d*|\s|$)/);

    const {
      additionnel_texte: additionalText
    } = yield select((state) => getAdditionalMedia(state, currentActivity));

    yield call(sendResponseForAutoCorrection, {
      authData: authData,
      params: {
        moduleId,
        responseId: responseId,
        question: additionalText,
        point,
        level: level ? level[0] : null,
        response: responseTxt,
        audio: learnerResponse
      }
    });
  } catch (error) {
    logger.error('autoCorrectWithAi error', error);
  }
}

export default flow;
